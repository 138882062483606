import React, { useContext, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import CssBaseline from '@mui/joy/CssBaseline'

import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  Dropdown,
  Input,
  IconButton,
  Textarea,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Menu,
  MenuItem,
  MenuButton,
  Modal,
  ModalClose,
  Sheet,
  Tooltip,
  Typography,
} from '@mui/joy'

import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import Delete from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'

import { CssVarsProvider, extendTheme } from '@mui/joy/styles'

import { DataProvider, DataLoader } from './Data'

import { GoogleOAuthProvider } from '@react-oauth/google'

import * as EmailValidator from 'email-validator'

import Markdown from 'react-markdown'

import '@fontsource-variable/cabin'

const theme = extendTheme({
  fontFamily: {
    display: "'Cabin Variable', sans-serif", // applies to `h1`–`h4`
    body: "'Cabin Variable', sans-serif", // applies to `title-*` and `body-*`
  },
  colorSchemes: {
    light: {
      palette: {},
    },
    dark: {
      palette: {},
    },
  },
})

const SignOut = () => {
  const { db, whoami } = useContext(DataProvider)

  return (
    <Box sx={{ position: 'fixed', top: 0, right: 0, mr: 1 }}>
      <Tooltip title={`${whoami} - Sign Out`}>
        <IconButton
          size="sm"
          variant="plain"
          color="neutral"
          onClick={() => {
            const fn = async () => {
              const res = await fetch('/api/sign-out')
              await res.json()
              console.log(res)
              window.location.reload()
            }
            fn()
          }}
        >
          <LogoutRoundedIcon />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

const envelopeName = (env) => {
  return Object.keys(env.filter.email || {})[0]
}

const EnvelopeList = ({ openEnvs, setOpenEnvs }) => {
  const [open, setOpen] = useState(false)

  const { db } = useContext(DataProvider)

  const sortedEnvelopes = useMemo(() => {
    const entries = Object.entries(db.envelope || {}).sort(
      ([_u1, e1], [_u2, e2]) => envelopeName(e1).localeCompare(envelopeName(e2))
    )

    console.log('ENT', entries)

    const usedCodes = {}

    const out = []

    for (const [envId, env] of entries) {
      const name = envelopeName(env)

      let suffix = 0
      let codeIdx = 0
      let code = name[codeIdx]

      while (code in usedCodes) {
        codeIdx += 1
        if (codeIdx >= name.length) {
          suffix += 1
          codeIdx = 0
        }
        code = name[codeIdx]
        if (suffix) {
          code += suffix
        }
      }

      code = code.toUpperCase()

      out.push({ code, envId, env, name })
    }

    return out
  }, [db.envelope])

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          sx={{ maxWidth: 500, p: 3, boxShadow: 'lg', borderRadius: 'md' }}
        >
          <NewEnvelope onClose={() => setOpen(false)} />
        </Sheet>
      </Modal>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '220px',
          maxHeight: '100vh',
          overflowY: 'auto',
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <List>
          {sortedEnvelopes.map(({ envId, env, code, name }) => (
            <ListItem sx={{ whiteSpace: 'nowrap' }}>
              <Tooltip title={name} placement="right">
                <Checkbox
                  checked={(openEnvs[envId] || {}).open}
                  onChange={(ev) => {
                    setOpenEnvs({
                      ...openEnvs,
                      [envId]: { open: ev.target.checked, code },
                    })
                  }}
                  label={code}
                />
              </Tooltip>
            </ListItem>
          ))}
        </List>
        <Box sx={{ ml: 1 }}>
          <Tooltip title="new envelope" placement="right">
            <IconButton onClick={() => setOpen(true)} variant="plain">
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </div>
    </>
  )
}

const NewEnvelope = ({ onClose }) => {
  const { whoami, fetchDb } = useContext(DataProvider)

  const [emailWith, setEmailWith] = useState('')
  const [filterPreview, setFilterPreview] = useState()

  const [creating, setCreating] = useState()

  const validEmail = EmailValidator.validate(emailWith)

  useEffect(() => {
    if (validEmail) {
      const go = { ok: true }

      const fn = async () => {
        const res = await fetch('/api/gmail-preview', {
          method: 'post',
          body: JSON.stringify({
            q: `from:${emailWith} OR to:${emailWith}`,
          }),
        })

        if (go.ok) {
          const out = await res.json()
          setFilterPreview(out)
        }
      }
      fn()

      return () => {
        go.ok = false
      }
    } else {
      setFilterPreview()
    }
  }, [emailWith, validEmail])

  return (
    <Box>
      <Typography>Create a new envelope</Typography>

      <Card sx={{ mb: 2, mt: 2 }}>
        <Input
          error={emailWith && !validEmail}
          value={emailWith}
          placeholder="Email address"
          onChange={(e) => setEmailWith(e.target.value)}
        />
        {validEmail ? (
          <Typography>
            All emails in <b>{whoami}</b> with a sender or recipient matching{' '}
            <b>{emailWith}</b> will be added to this envelope{' '}
            <Typography color="danger">
              and removed from the <b>{whoami}</b> mailbox.
            </Typography>
          </Typography>
        ) : (
          ''
        )}

        {validEmail ? (
          filterPreview ? (
            <List>
              {filterPreview.snippets.map(({ id, snippet }) => (
                <ListItem key={id}>
                  <Typography level="body-sm" noWrap>
                    {snippet}
                  </Typography>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography>Loading preview...</Typography>
          )
        ) : (
          ''
        )}
      </Card>

      <Button
        onClick={() => {
          setCreating(true)
          const fn = async () => {
            await fetch('/api/new-envelope', {
              method: 'post',
              body: JSON.stringify({
                email: emailWith,
              }),
            })

            setCreating(false)

            await fetchDb()

            onClose()
          }
          fn()
        }}
        loading={creating}
        disabled={!validEmail}
      >
        Create Envelope
      </Button>
    </Box>
  )
}

const EnvelopeViewer = ({ openEnvs }) => {
  const [envs, setEnvs] = useState({})

  useEffect(() => {
    // Load envelopes as they're checked...

    const fn = async () => {
      const newEnvs = { ...envs }
      let change = false

      for (const [envId, { open }] of Object.entries(openEnvs)) {
        if (!open) {
          continue
        }
        if (!(envId in envs)) {
          const res = await fetch('/api/fetch-envelope', {
            method: 'post',
            body: JSON.stringify({ id: envId }),
          })
          const env = await res.json()

          newEnvs[envId] = env
          change = true
        }
      }

      if (change) {
        setEnvs(newEnvs)
      }
    }
    fn()
  }, [envs, openEnvs])

  const body = useMemo(() => {
    const parts = []

    const allEnvs = Object.entries(openEnvs)
      .filter((e) => e[1].open)
      .map((e) => [e[0], envs[e[0]]])
    if (allEnvs.filter((x) => !x[1]).length) {
      // Something is still loading...
      return parts
    }

    let envCount = 0

    for (const [envId, env] of allEnvs) {
      const envLetter = openEnvs[envId].code
      let msgNumber = 1

      for (const [msgId, { date, block }] of Object.entries(env)) {
        const blocks = Object.values(block).sort((x, y) =>
          x.order > y.order ? 1 : -1
        )

        parts.push({ envId, date, blocks, envLetter, msgNumber, msgId })
        msgNumber += 1
      }

      envCount += 1
    }

    parts.sort((x, y) => (x.date > y.date ? 1 : -1))

    return parts
  }, [envs, openEnvs])

  const envCnt = Object.values(openEnvs).filter((x) => x.open).length

  const sheetStyle = { pl: 6, pr: 4, pt: 2, pb: 4, minHeight: '100vh' }

  if (!envCnt) {
    return <Sheet variant="plain" sx={sheetStyle} />
  }

  if (!body || !body.length) {
    return (
      <Sheet variant="plain" sx={sheetStyle}>
        Loading...
      </Sheet>
    )
  }

  return (
    <Sheet variant="plain" sx={sheetStyle}>
      {body.map(({ envId, date, blocks, envLetter, msgNumber, msgId }) => {
        return (
          <>
            <Typography level="body-sm">
              {new Date(date).toString()}
            </Typography>
            <Box mb={2}>
              {(blocks || [])
                .filter((x) => !x.quote)
                .map((x, blockIdx) => (
                  <>
                    <Typography
                      level="body-xs"
                      sx={{
                        textAlign: 'left',
                        position: 'absolute',
                        left: '4px',
                      }}
                    >
                      {envLetter}:{msgNumber}.{blockIdx + 1}
                    </Typography>
                    {x.text ? <Typography>{x.text}</Typography> : ''}
                    {x.img ? (
                      <MdImage
                        src={`/api/get-image?env=${envId}&ps=${msgId}&name=${x.img}`}
                      />
                    ) : (
                      ''
                    )}
                    {x.md ? (
                      <Markdown
                        components={{
                          img(props) {
                            const { node, ...rest } = props
                            return <MdImage {...rest} />
                          },
                        }}
                      >
                        {x.md}
                      </Markdown>
                    ) : (
                      ''
                    )}
                  </>
                ))}
            </Box>
          </>
        )
      })}
    </Sheet>
  )
}

const MdImage = (props) => {
  return <img {...props} style={{ width: '100%' }} />
}

const Main = ({ children }) => {
  const [openEnvs, setOpenEnvs] = useState({})

  return (
    <>
      <EnvelopeList openEnvs={openEnvs} setOpenEnvs={setOpenEnvs} />

      <SignOut />

      <Container maxWidth="xl" sx={{ maxWidth: 'calc(100% - 100px)' }}>
        <EnvelopeViewer openEnvs={openEnvs} />
      </Container>
    </>
  )
}

const OAUTH_CLIENT_ID =
  window.location.host === 'envelo.ps'
    ? '395582500294-l1amcouv4h6ega2tmn005j3kukfjpotc.apps.googleusercontent.com'
    : '630806176702-e9eivmqtkene08k3n1po8eqfemqhdftv.apps.googleusercontent.com'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <CssVarsProvider
      defaultMode="system"
      theme={theme}
      modeStorageKey="envelops-system-mode"
      disableNestedContext
    >
      <CssBaseline />
      <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
        <DataLoader>
          <Main />
        </DataLoader>
      </GoogleOAuthProvider>
    </CssVarsProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
